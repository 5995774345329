/* Calendar */
.CalendarDay__default {
    border: none !important;
    width: 100%;
}

.DayPicker_transitionContainer, .DayPicker__horizontal {
    border-radius: 20px !important;
    width: 100%;
}



/* AG GRID */

.ag-theme-alpine {
  font-family: 'Open Sans' !important;
}
.ag-side-button-label {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .ag-theme-alpine .ag-root-wrapper {
    border-radius: .6rem;
    border-color: #ECECEC !important;
  }


/*  Version */
.versionWrap { position: fixed; left: 10px; bottom: 5px; font-size: 12px; font-weight: 600; opacity: 0.7;background: whitesmoke; }

.link {
  color: blue;
  text-decoration:  underline;
}